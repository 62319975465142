/**
 * Imports
 */
import { createReducer } from '@reduxjs/toolkit';

import {
  showAddOwnerModal,
  hideAddOwnerModal,
  clearFilters,
  setFilters,
  SetFiltersAction,
} from './actions';

/**
 * Types
 */
export type MerchantFilters = {
  clearhausStatus: boolean;
  clearhausEcomStatus: boolean;
  countryCode: string[] | null;
  isLive: boolean;
  vibrantStatus: boolean;
  ecom: boolean;
  paymentLinks: boolean;
  inStore: boolean;
};
export interface MerchantsState {
  modals: {
    ownerModal: boolean;
  };
  filters: MerchantFilters;
}

/**
 * Constants
 */
const INITIAL_STATE: MerchantsState = {
  modals: {
    ownerModal: false,
  },
  filters: {
    clearhausStatus: false,
    clearhausEcomStatus: false,
    countryCode: null,
    isLive: false,
    vibrantStatus: false,
    ecom: false,
    paymentLinks: false,
    inStore: false,
  },
};

/**
 * `merchants` reducer
 */
const merchantReducer = createReducer(INITIAL_STATE, {
  /**
   * SHOW_OWNER_MODAL
   */
  [showAddOwnerModal.type]: (state) => {
    state.modals.ownerModal = true;
  },
  /**
   * HIDE_OWNER_MODAL
   */
  [hideAddOwnerModal.type]: (state) => {
    state.modals.ownerModal = false;
  },
  /**
   * SET_FILTERS
   */
  [setFilters.type]: (state, action: SetFiltersAction) => {
    state.filters = action.payload;
  },
  /**
   * CLEAR_FILTERS
   */
  [clearFilters.type]: (state) => {
    state.filters.countryCode = null;
  },
});

/**
 * Exports
 */
export default merchantReducer;
