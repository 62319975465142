import { SearchOutlined } from '@ant-design/icons';
import { Card, Checkbox, Spin, Statistic, Typography } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { SelectValue } from 'antd/lib/select';
import { Select, Space, Box } from 'components';
import { MerchantsTable } from 'features/merchants/components';
import { useMerchants } from 'features/merchants/hooks';
import {
  clearFilters,
  filtersSelector,
  setFilters,
} from 'features/merchants/redux';
import { BaseLayout } from 'features/navigation/components';
import {
  isAdminSelector,
  isLink4PaySupporterSelector,
} from 'features/user/redux/selectors';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { VibrantColors, styled } from 'theme';
/**
 * Styling
 */
const OptionsMenuBar = styled.div`
  border: 1px solid ${VibrantColors.Border.BorderPrimary};
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  margin: 16px 0 32px;
  gap: 16px;
  padding: 24px 16px;

  & > *:not(:first-of-type) {
    @media (max-width: 768px) {
      margin: 16px 0 0;
    }
  }
  @media (max-width: 768px) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 0;
  }
`;

const OptionsMenuBarItem = styled.div`
  margin-right: 16px;
`;

const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const StyledSelect = styled(Select)`
  && {
    min-width: 300px;
    .ant-select-selector .ant-select-selection-search-input {
      height: 42px;
    }
    .ant-select-arrow svg {
      fill: ${({ theme }) => theme.colors.Text.UI};
    }
  }
`;
const StyledOption = styled(Select.Option)``;

const MerchantsPage: React.FC = () => {
  const isAdmin = useSelector(isAdminSelector);
  const isLink4Pay = useSelector(isLink4PaySupporterSelector);
  const filters = useSelector(filtersSelector);
  const dispatch = useDispatch();
  // if admin and not link4pay
  const canFilterLink4Pay = isAdmin && !isLink4Pay;
  const {
    merchants,
    merchantsSelectOpts,
    ownerSelectOpts,
    handlers: { onClearSelectMerchant, onSelectMerchant, onShowLink4Pay },
  } = useMerchants();

  const _onChange = (merchantId: SelectValue) => {
    onSelectMerchant(merchantId as string);
  };

  const _onFilterCountry = (values: SelectValue) => {
    if (values) {
      // @ts-ignore
      dispatch(setFilters({ ...filters, countryCode: [values] }));
    }
  };
  const _onFilterVibrantStatus = (e: CheckboxChangeEvent) => {
    dispatch(
      setFilters({
        ...filters,
        clearhausEcomStatus: false,
        clearhausStatus: false,
        vibrantStatus: e.target.checked,
      }),
    );
  };
  const _onFilterClearhausStatus = (e: CheckboxChangeEvent) => {
    dispatch(
      setFilters({
        ...filters,
        clearhausEcomStatus: false,
        vibrantStatus: false,
        clearhausStatus: e.target.checked,
      }),
    );
  };
  const _onFilterClearhausEcomStatus = (e: CheckboxChangeEvent) => {
    dispatch(
      setFilters({
        ...filters,
        clearhausStatus: false,
        vibrantStatus: false,
        clearhausEcomStatus: e.target.checked,
      }),
    );
  };
  const _onFilterEcom = (e: CheckboxChangeEvent) => {
    dispatch(
      setFilters({
        ...filters,
        ecom: e.target.checked,
      }),
    );
  };
  const _onFilterInstore = (e: CheckboxChangeEvent) => {
    dispatch(
      setFilters({
        ...filters,
        inStore: e.target.checked,
      }),
    );
  };
  const _onFilterPaymentLnks = (e: CheckboxChangeEvent) => {
    dispatch(
      setFilters({
        ...filters,
        paymentLinks: e.target.checked,
      }),
    );
  };
  const _onClearFilters = () => {
    dispatch(clearFilters({}));
  };

  if (!merchants) {
    <BaseLayout fullHeight>
      <Spin />
    </BaseLayout>;
  }

  return (
    <BaseLayout>
      <Row>
        <Typography.Title>Merchants</Typography.Title>
        <Card>
          <Box minWidth="100px">
            <Statistic title="Count" value={merchants?.length} />
          </Box>
        </Card>
      </Row>
      <OptionsMenuBar>
        <Box
          display="flex"
          alignItems="center"
          width="100%"
          flexDirection="row"
        >
          <Box mr="12px">
            <StyledSelect
              allowClear
              showSearch
              suffixIcon={<SearchOutlined />}
              // @ts-ignore
              filterOption={(input, option) =>
                option &&
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              placeholder="Select Merchant"
              onChange={(merchantId: SelectValue) => _onChange(merchantId)}
              onClear={onClearSelectMerchant}
            >
              {merchantsSelectOpts.map((merchant) => {
                return (
                  <StyledOption
                    key={merchant.merchantId}
                    value={merchant.merchantId}
                  >
                    {merchant.name}
                  </StyledOption>
                );
              })}
            </StyledSelect>
          </Box>
          <Box mr="12px">
            <StyledSelect
              allowClear
              showSearch
              suffixIcon={<SearchOutlined />}
              // @ts-ignore
              filterOption={(input, option) =>
                option &&
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              placeholder="Select Owner"
              onChange={(merchantId: SelectValue) => _onChange(merchantId)}
              onClear={onClearSelectMerchant}
            >
              {ownerSelectOpts.map((owner) => {
                return (
                  <StyledOption key={owner.name} value={owner.merchantId}>
                    {owner.name}
                  </StyledOption>
                );
              })}
            </StyledSelect>
          </Box>
          {isAdmin && (
            <Box>
              <React.Fragment>
                <StyledSelect
                  allowClear
                  placeholder="Select country"
                  defaultValue={
                    filters?.countryCode ? filters.countryCode[0] : undefined
                  }
                  onClear={_onClearFilters}
                  onChange={(countryCode: SelectValue) =>
                    _onFilterCountry(countryCode)
                  }
                >
                  <StyledOption value={'DK'}>Denmark</StyledOption>
                  <StyledOption value={'FO'}>Faroe Island</StyledOption>
                  <StyledOption value={'GL'}>Greenland</StyledOption>
                  <StyledOption value={'NO'}>Norway</StyledOption>
                  <StyledOption value={'ES'}>Spain</StyledOption>
                  <StyledOption value={'SE'}>Sweden</StyledOption>
                </StyledSelect>
              </React.Fragment>
            </Box>
          )}
        </Box>
        <Box display="flex" alignItems="center" width="100%" mt="12px">
          {!isLink4Pay && (
            <OptionsMenuBarItem>
              <Checkbox onChange={_onFilterInstore} checked={filters.inStore}>
                In-store Merchants
              </Checkbox>
            </OptionsMenuBarItem>
          )}
          {!isLink4Pay && (
            <OptionsMenuBarItem>
              <Checkbox onChange={_onFilterEcom} checked={filters.ecom}>
                E-com Merchants
              </Checkbox>
            </OptionsMenuBarItem>
          )}
          {!isLink4Pay && (
            <OptionsMenuBarItem>
              <Checkbox
                onChange={_onFilterPaymentLnks}
                checked={filters.paymentLinks}
              >
                Payment links Merchants
              </Checkbox>
            </OptionsMenuBarItem>
          )}
          {!isLink4Pay && (
            <OptionsMenuBarItem>
              <Checkbox
                onChange={_onFilterVibrantStatus}
                checked={filters.vibrantStatus}
              >
                Vibrant Pending
              </Checkbox>
            </OptionsMenuBarItem>
          )}
          {!isLink4Pay && (
            <OptionsMenuBarItem>
              <Checkbox
                onChange={_onFilterClearhausStatus}
                checked={filters.clearhausStatus}
              >
                Clearhaus In-store Pending
              </Checkbox>
            </OptionsMenuBarItem>
          )}
          {!isLink4Pay && (
            <OptionsMenuBarItem>
              <Checkbox
                onChange={_onFilterClearhausEcomStatus}
                checked={filters.clearhausEcomStatus}
              >
                Clearhaus Ecom Pending
              </Checkbox>
            </OptionsMenuBarItem>
          )}
          {canFilterLink4Pay && (
            <OptionsMenuBarItem>
              <Checkbox onChange={onShowLink4Pay}>Show Link4Pay</Checkbox>
            </OptionsMenuBarItem>
          )}
        </Box>
      </OptionsMenuBar>
      <MerchantsTable merchants={merchants} />
    </BaseLayout>
  );
};

export { MerchantsPage };
